import axios from 'axios';
import config from '../config/default.json';

axios.defaults.baseURL = config.BACKEND_URL;

axios.interceptors.response.use(function (response) {
    //let successful responses continue on...
    return response;
}, function (error) {

    if (error.response && error.response.status === 401 && error.response.config && error.response.config.headers['Authorization']) {
        //failed authorization, remove key and send to login page
        localStorage.removeItem('App-Authentication-Token');
        window.location.href = '/app/login';
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default axios;
