import React, { Suspense, lazy } from 'react';
import './App.css';

import LoginPage from "./pages/LoginPage.js";
import LoadingScreen from "./pages/LoadingScreen.js";

import {useSelector} from "react-redux";
import RestrictedRoute from "./RestrictedRoute.js";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


function lazyWithTimeout(func) {
  return lazy(() => {
    return Promise.all([
      new Promise((resolve) => { func().then((data) => resolve(data)); }),
      new Promise(resolve => setTimeout(resolve, 1200))
    ])
    .then(([moduleExports]) => moduleExports);
  });
}


function App() {
  const authState = useSelector(state => state.auth);
  const BuilderPage = lazyWithTimeout(() => import('./pages/BuilderPage.js'));
  const DesignsListPage = lazyWithTimeout(() => import('./pages/DesignsListPage.js'));

  return (
    <div id="App" className="App">
      <Router>
        <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <RestrictedRoute exact path="/app/login" allow={!authState.isAuthenticated} fallback="/app/builder/new" component={LoginPage} />
          <RestrictedRoute exact path="/app/designs" allow={authState.isAuthenticated} fallback="/app/login" component={DesignsListPage} />
          <RestrictedRoute exact path="/app/builder/:id" allow={authState.isAuthenticated} fallback="/app/login" component={BuilderPage} />

          <Route exact path="/app">
            <Redirect to="/app/builder/new" />
          </Route>
        </Switch>
        </Suspense>
      </Router>

      <ToastContainer />
    </div>
  );
}

export default App;
