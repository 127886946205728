import React from 'react';

import './LoadingScreen.css';
import {Link, Redirect, Route} from "react-router-dom";
import {logoutUser} from "../store/auth.js";
import {useDispatch} from "react-redux";

function LoadingScreen() {

    const dispatch = useDispatch();

    return (
        <div className="LoadingScreen">

            <nav className="navbar navbar-dark bg-primary navbar-expand-sm sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand py-3 animate-move" href="#top"><img src="/app/images/ideas.png"
                                                                                   style={{
                                                                                       height: '23px',
                                                                                       width: 'auto',
                                                                                       marginRight: '12px'
                                                                                   }}/>
                        Cute Apps Builder</a>
                    <button className="navbar-toggler" style={{border: 0, outline: 'none'}} type="button"
                            data-toggle="collapse" data-target="#navbarResponsive"
                            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon py-3 px-3"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link px-3 py-3" to="/">Home</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link px-3 py-3" to="/app/builder/new">Create new design</Link>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link px-3 py-3" href="#" onClick={(e) => { e.preventDefault(); e.target.blur(); dispatch(logoutUser()); }}>Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


            <div className="container">
                <div className="row justify-content-md-center">

                    <div className="col-12 col-lg-12">


                        <div className="loading-animation text-center mt-4 pt-5" style={{ opacity: 0}}>
                            <div className="spinner-border text-danger pt-5 mt-5" style={{width: '5rem', height: '5rem'}}
                                 role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div className="text-center">
                            <div className="lds-facebook" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;
