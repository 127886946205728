import React, {useState} from 'react';
import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import './LoginPage.css';
import {signInUser} from "../store/auth.js";

function LoginPage() {

    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);

    const [email, setEmail] = useState('');
    const [openNavbar, setOpenNavbar] = useState(false);
    const [password, setPassword] = useState('');

    return (
        <div className="LoginPage">

            <nav className="navbar navbar-dark bg-info navbar-expand-sm sticky-top">
                <div className="container">
                    <a className="navbar-brand py-3 animate-move" href="#top">Cute Apps Builder</a>
                    <button className="navbar-toggler" style={{border: 0, outline: 'none'}} type="button"
                            data-toggle="collapse" data-target="#navbarResponsive" onClick={(e) => { e.preventDefault(); e.target.blur(); setOpenNavbar(!openNavbar); }}
                            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon py-3 px-3"></span>
                    </button>
                    <div className={"collapse navbar-collapse" + (openNavbar ? ' show' : '')} id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link px-3 py-3" href="/app/login">Sign up</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link px-3 py-3" href="/#features">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link px-3 py-3" href="/#pricing">Pricing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-7 col-lg-6">


                        <div className={"auth-form-container" + ((authState && authState.failedAuthRequest) ? ' bounce' : '')}>
                            <div className="auth-form">
                                <div style={{ opacity: (authState && authState.pendingAuthRequest) ? 0.3 : 1 }}>

                                <h3 className="mb-2 text-uppercase">Cute Apps</h3>

                                {(authState && authState.failedAuthRequest)
                                ? <p className="mb-4" style={{ opacity: 0.85, color: '#bb0000' }}>Invalid email or password!</p>
                                : <p className="mb-4" style={{ opacity: 0.85 }}>Please enter your credentials</p>}


                                <form>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <span className="img-icon envelope" />
                                            </div>
                                        </div>

                                        <input type="text" className="form-control" autoFocus={true} placeholder="E-mail address" value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>


                                    <div className="input-group mt-2 mb-4">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <span className="img-icon key" />
                                            </div>
                                        </div>

                                        <input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                                    </div>

                                    <div>
                                        <button className="btn btn-block btn-info" onClick={(e) => { e.preventDefault(); e.target.blur(); dispatch(signInUser(email, password)); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="form-links">

                            <p className="mt-3 mb-0" style={{ display: 'none', opacity: 0 }}>
                                <Link to='/forgotten'>Forgot your password?</Link>
                            </p>

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
