import { push } from 'connected-react-router';
import request from '../lib/request.js';
import {LOGOUT_USER} from "./auth.js";

export const LOAD_BLOCKS_STARTED = 'designs/LOAD_BLOCKS_STARTED';
export const LOAD_BLOCKS_FAILED = 'designs/LOAD_BLOCKS_FAILED';
export const LOAD_BLOCKS_FINISHED = 'designs/LOAD_BLOCKS_FINISHED';


const initialState = {
    blocks: {},

    lastError: null,
    pendingLoadBlocksRequest: false,
    failedLoadBlocksRequest: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOAD_BLOCKS_STARTED:
            return {
                ...state,

                blocks: {},
                lastError: null,
                pendingLoadBlocksRequest: true,
                failedLoadBlocksRequest: false
            };

        case LOAD_BLOCKS_FAILED:
            return {
                ...state,

                lastError: action.error,
                pendingLoadBlocksRequest: false,
                failedLoadBlocksRequest: true
            };

        case LOAD_BLOCKS_FINISHED:
            return {
                ...state,

                blocks: action.blocks,
                lastError: null,
                pendingLoadBlocksRequest: false,
                failedLoadBlocksRequest: false
            };

        case LOGOUT_USER:
            return {
                ...state,

                blocks: {},
                lastError: null,
                pendingLoadBlocksRequest: false,
                failedLoadBlocksRequest: false
            };

        default:
            return state
    }
}





export const loadBlocks = () => {

    return dispatch => {
        dispatch({
            type: LOAD_BLOCKS_STARTED
        });

        let authenticationToken = localStorage.getItem('App-Authentication-Token');
        return request.get('/blocks/', { headers: { Authorization: `Bearer ${authenticationToken}` } })
            .then(({ data }) => {

                dispatch({
                    type: LOAD_BLOCKS_FINISHED,
                    blocks: data.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: LOAD_BLOCKS_FAILED
                });
            });
    }
};
